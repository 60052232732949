import React from "react";

class ConsignmentOver extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
     return (
      <div>
        <h3>2023 Cochran's Ski Sale Consignment is Closed</h3>
        <p>Thank you for your interest in the 2024 Cochran's Ski Sale! Our consignment is now closed and we are getting ready
        for the <a href="https://www.cochranskisale.com">ski sale!</a> The sale is Saturday Nov 2nd and Sunday Nov 3rd at Camel's Hump Middle School
        See you there!</p>

        <p>For support please email <a href="mailto:skisale@cochranskiclub.com">skisale@cochranskiclub.com</a></p>
      </div>
    );
  }
}

export default ConsignmentOver;