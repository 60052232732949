import React from "react";
import {Table} from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import config from './config';

const apiBaseUrl = `${config.API_ENDPOINT}`;

const sessionExpirationMessage = "Your session has expired, please check your email for a link to resume your consignment";

class ConsignmentConfirmation extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        items: [],
        consigner: {}
      }

      this.addItem = props.addItem;
      this.getAuth = props.getAuth;
      this.getConsignerInfo = props.getConsignerInfo;
  }

  setLoading(state, text) {
    this.setState({
      ...this.state,
      loading: state,
      loadingText: text
    });
  }

  async componentDidMount() {
    if (!this.getAuth()) {
      this.props.history.push({pathname: '/', state: {messageType: "warning", messageText: sessionExpirationMessage}});
      return;
    }

    this.setLoading(true, "Loading consigned items...");
    const consigner = this.getConsignerInfo();
    console.log(`consigner: ${consigner}`);

    const fetchOptions = {
      method: "GET",
      headers: {
          'Authorization': `Bearer ${this.getAuth().token}`
      }
    }

    const itemsUrl = `${apiBaseUrl}/items?consignerId=${consigner.id}`;
    console.log(`Retrieving items for consignerId ${consigner.id}`);

    const itemsResponse = await fetch(itemsUrl, fetchOptions);
    const items = await itemsResponse.json();
    console.log(`Retrieved items: ${JSON.stringify(items)}`);

    this.setState({
      ...this.state,
      consigner: consigner,
      items: items
    });

    this.props.clearState();

    this.setLoading(false, "");
  }

  render() {
    const component = this;
    const consigner = this.state.consigner;

    const itemRows = this.state.items.map(function(item) {
      return (
        <tr key={item.id}>
          <td>{item.idOverride ? component.getAuth().vendorId.concat(item.idOverride) : item.id}</td>
          <td>{item.title}</td>
          <td>{item.category}</td>
          <td>{item.condition}</td>
          <td>{item.size}</td>
          <td>{item.price}</td>
          <td>{item.discountEligible ? "Yes" : "No"}</td>
          <td>{item.donateProceeds ? "Yes" : "No"}</td>
        </tr>
      )
    });


    let consignerInfo = ("")
    if (consigner.firstName) {
      consignerInfo = (
        <div>
          <h4>Your Contact Information</h4>
          <p><em>If you see any errors with your personal information, please contact us at <a href="mailto:skisale@cochranskiclub.com">skisale@cochranskiclub.com</a>.</em></p>
          <p>Name: {consigner.firstName} {consigner.lastName}</p>
          <p>Email Address: {consigner.emailAddress}</p>
          <p>Phone Number: {consigner.phoneNumber}</p>
          <p>Mailing Address: {consigner.mailingAddress} {consigner.city}, {consigner.state} {consigner.zipcode}</p>
        </div>
      );
    }

    return(
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingText}
      >
        <h3>Thank You!</h3>
        <p>Thank you for consigning your gear at the 2024 Cochran's Ski Sale!</p>
        <p>You will be receiving an email shortly with a confirmation of your consignment. You can now close this browser window.</p>

        {consignerInfo}

        <h4>Your Consigned Items</h4>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Item ID</th>
              <th>Item Name</th>
              <th>Category</th>
              <th>Condition</th>
              <th>Size</th>
              <th>Price</th>
              <th>Sunday Special</th>
              <th>Donation</th>
            </tr>
          </thead>
          <tbody>
            {itemRows}
          </tbody>
        </Table>

        <p>For support please email <a href="mailto:skisale@cochranskiclub.com">skisale@cochranskiclub.com</a></p>
      </LoadingOverlay>
    );
  }
}

export default ConsignmentConfirmation;
